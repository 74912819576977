import { Action, createReducer, on } from "@ngrx/store";

import * as fromActions from "../../store/actions";

export interface INotificationState {
  pending: boolean;
  error: IError;
  firebaseToken: string;
}

export const initialState: INotificationState = {
  pending: false,
  error: undefined,
  firebaseToken: undefined,
};

export const notificationReducer = createReducer(
  initialState,
  on(fromActions.loadFirebaseToken, (state) => ({
    ...state,
    pending: true,
    error: undefined,
  })),
  on(fromActions.loadFirebaseTokenSuccess, (state, { payload }) => ({
    ...state,
    pending: false,
    firebaseToken: payload,
  })),
  on(fromActions.loadFirebaseTokenFailure, (state, { payload }) => ({
    ...state,
    pending: false,
    error: payload,
  }))
);

export function reducer(
  state: INotificationState | undefined,
  action: Action
): INotificationState {
  return notificationReducer(state, action);
}

export const getNotificationPending = (state: INotificationState) =>
  state.pending;
export const getNotificationError = (state: INotificationState) => state.error;
export const getNotificationFirebaseToken = (state: INotificationState) =>
  state.firebaseToken;
