export const specialPages = [
  { specialPage: 77, prevSpecialPage: 76, chapterName: "004" },
  { specialPage: 208, prevSpecialPage: 207, chapterName: "010" },
  { specialPage: 332, prevSpecialPage: 331, chapterName: "022" },
  { specialPage: 342, prevSpecialPage: 341, chapterName: "023" },
  { specialPage: 350, prevSpecialPage: 349, chapterName: "024" },
  { specialPage: 367, prevSpecialPage: 366, chapterName: "026" },
  { specialPage: 377, prevSpecialPage: 376, chapterName: "027" },
  { specialPage: 415, prevSpecialPage: 414, chapterName: "032" },
  { specialPage: 418, prevSpecialPage: 417, chapterName: "033" },
  { specialPage: 446, prevSpecialPage: 445, chapterName: "037" },
  { specialPage: 453, prevSpecialPage: 452, chapterName: "038" },
  { specialPage: 499, prevSpecialPage: 498, chapterName: "045" },
  { specialPage: 507, prevSpecialPage: 506, chapterName: "047" },
  { specialPage: 526, prevSpecialPage: 525, chapterName: "053" },
  { specialPage: 549, prevSpecialPage: 548, chapterName: "060" },
  { specialPage: 556, prevSpecialPage: 555, chapterName: "064" },
  { specialPage: 558, prevSpecialPage: 557, chapterName: "065" },
  { specialPage: 585, prevSpecialPage: 584, chapterName: "080" },
  { specialPage: 587, prevSpecialPage: 586, chapterName: "082" },
  { specialPage: 591, prevSpecialPage: 590, chapterName: "086" },
  { specialPage: 595, prevSpecialPage: 594, chapterName: "091" },
];
