import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";

import { DOCUMENT } from "@angular/common";
import { AuthService, MessagingService } from "./services";
import {
  VCFO_ORGANIZATION_ID,
  QUPIL_ORGANIZATION_ID,
  ORGANIZATION_ID,
  QUPIL_LANGUAGE_EN,
  VCFO_LANGUAGE_EN,
} from "./constants";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private afMessaging: AngularFireMessaging,
    private message: MessagingService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    // get base url
    // for example: vcfo.net
    const baseUrl = window.location.origin;

    // if base url includes vcfo
    // set vcfo organization id
    // use "localhost" to test vcfo in your local
    if (
      baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== VCFO_ORGANIZATION_ID
    ) {
      this.authService.setOrganizationId(VCFO_ORGANIZATION_ID);

      // if not json strings stored with local storage
      if (!localStorage.getItem("en"))
        this.authService.setLanguage("en", VCFO_LANGUAGE_EN);

      window.location.reload();
    } else if (
      !baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== QUPIL_ORGANIZATION_ID
    ) {
      // else set default organization id
      this.authService.setOrganizationId(QUPIL_ORGANIZATION_ID);

      // if not json strings stored with local storage
      if (!localStorage.getItem("en"))
        this.authService.setLanguage("en", QUPIL_LANGUAGE_EN);

      window.location.reload();
    }

    if (this.authService.getOrganizationId() == QUPIL_ORGANIZATION_ID)
      this.message.requestPermission();
    this.afMessaging.requestPermission.subscribe((data) => {});
    this.message.receiveMessage();

    // get images and favicon and logos
    await this.authService.getLogoAndImages(ORGANIZATION_ID).toPromise();

    // get languages strings and save it in local storage
    await this.authService.getLanguages(ORGANIZATION_ID).toPromise();

    // after get icon from backend
    // assign this icon to brawser tab
    // by link tag
    var link = document.createElement("link");
    link.setAttribute("rel", "icon");
    link.setAttribute("type", "image/x-icon");
    link.setAttribute("href", localStorage.getItem("logo"));
    document.head.appendChild(link);
  }
}
