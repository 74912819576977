import * as fromActions from "../actions";

export function clearState(reducer) {
  return function (state, action) {
    if (action.type === fromActions.logoutOrganizationSuccess.type) {
      state = {
        router: {
          state: {
            url: "/auth/login",
            queryParams: {},
            params: {},
          },
        },
        notification: state?.notification,
      };
    }
    return reducer(state, action);
  };
}
