export interface IChapter {
  id: number;
  revelation_place: string;
  revelation_order: number;
  bismillah_pre: boolean;
  name_complex: string;
  name_arabic: string;
  verses_count: number;
  pages: [number, number];
  name_simple: string;
  translated_name: {
    language_name: string;
    name: string;
  };
}
export const chapters: IChapter[] = [
  {
    id: 1,
    revelation_place: "makkah",
    revelation_order: 5,
    bismillah_pre: false,
    name_simple: "Al-Fatihah",
    name_complex: "Al-Fātiĥah",
    name_arabic: "الفاتحة",
    verses_count: 7,
    pages: [1, 1],
    translated_name: { language_name: "english", name: "The Opener" },
  },
  {
    id: 2,
    revelation_place: "madinah",
    revelation_order: 87,
    bismillah_pre: true,
    name_simple: "Al-Baqarah",
    name_complex: "Al-Baqarah",
    name_arabic: "البقرة",
    verses_count: 286,
    pages: [2, 49],
    translated_name: { language_name: "english", name: "The Cow" },
  },
  {
    id: 3,
    revelation_place: "madinah",
    revelation_order: 89,
    bismillah_pre: true,
    name_simple: "Ali 'Imran",
    name_complex: "Āli `Imrān",
    name_arabic: "آل عمران",
    verses_count: 200,
    pages: [50, 76],
    translated_name: { language_name: "english", name: "Family of Imran" },
  },
  {
    id: 4,
    revelation_place: "madinah",
    revelation_order: 92,
    bismillah_pre: true,
    name_simple: "An-Nisa",
    name_complex: "An-Nisā",
    name_arabic: "النساء",
    verses_count: 176,
    pages: [77, 106],
    translated_name: { language_name: "english", name: "The Women" },
  },
  {
    id: 5,
    revelation_place: "madinah",
    revelation_order: 112,
    bismillah_pre: true,
    name_simple: "Al-Ma'idah",
    name_complex: "Al-Mā'idah",
    name_arabic: "المائدة",
    verses_count: 120,
    pages: [106, 127],
    translated_name: { language_name: "english", name: "The Table Spread" },
  },
  {
    id: 6,
    revelation_place: "makkah",
    revelation_order: 55,
    bismillah_pre: true,
    name_simple: "Al-An'am",
    name_complex: "Al-'An`ām",
    name_arabic: "الأنعام",
    verses_count: 165,
    pages: [128, 150],
    translated_name: { language_name: "english", name: "The Cattle" },
  },
  {
    id: 7,
    revelation_place: "makkah",
    revelation_order: 39,
    bismillah_pre: true,
    name_simple: "Al-A'raf",
    name_complex: "Al-'A`rāf",
    name_arabic: "الأعراف",
    verses_count: 206,
    pages: [151, 176],
    translated_name: { language_name: "english", name: "The Heights" },
  },
  {
    id: 8,
    revelation_place: "madinah",
    revelation_order: 88,
    bismillah_pre: true,
    name_simple: "Al-Anfal",
    name_complex: "Al-'Anfāl",
    name_arabic: "الأنفال",
    verses_count: 75,
    pages: [177, 186],
    translated_name: { language_name: "english", name: "The Spoils of War" },
  },
  {
    id: 9,
    revelation_place: "madinah",
    revelation_order: 113,
    bismillah_pre: false,
    name_simple: "At-Tawbah",
    name_complex: "At-Tawbah",
    name_arabic: "التوبة",
    verses_count: 129,
    pages: [187, 207],
    translated_name: { language_name: "english", name: "The Repentance" },
  },
  {
    id: 10,
    revelation_place: "makkah",
    revelation_order: 51,
    bismillah_pre: true,
    name_simple: "Yunus",
    name_complex: "Yūnus",
    name_arabic: "يونس",
    verses_count: 109,
    pages: [208, 221],
    translated_name: { language_name: "english", name: "Jonah" },
  },
  {
    id: 11,
    revelation_place: "makkah",
    revelation_order: 52,
    bismillah_pre: true,
    name_simple: "Hud",
    name_complex: "Hūd",
    name_arabic: "هود",
    verses_count: 123,
    pages: [221, 235],
    translated_name: { language_name: "english", name: "Hud" },
  },
  {
    id: 12,
    revelation_place: "makkah",
    revelation_order: 53,
    bismillah_pre: true,
    name_simple: "Yusuf",
    name_complex: "Yūsuf",
    name_arabic: "يوسف",
    verses_count: 111,
    pages: [235, 248],
    translated_name: { language_name: "english", name: "Joseph" },
  },
  {
    id: 13,
    revelation_place: "madinah",
    revelation_order: 96,
    bismillah_pre: true,
    name_simple: "Ar-Ra'd",
    name_complex: "Ar-Ra`d",
    name_arabic: "الرعد",
    verses_count: 43,
    pages: [249, 255],
    translated_name: { language_name: "english", name: "The Thunder" },
  },
  {
    id: 14,
    revelation_place: "makkah",
    revelation_order: 72,
    bismillah_pre: true,
    name_simple: "Ibrahim",
    name_complex: "Ibrāhīm",
    name_arabic: "ابراهيم",
    verses_count: 52,
    pages: [255, 261],
    translated_name: { language_name: "english", name: "Abraham" },
  },
  {
    id: 15,
    revelation_place: "makkah",
    revelation_order: 54,
    bismillah_pre: true,
    name_simple: "Al-Hijr",
    name_complex: "Al-Ĥijr",
    name_arabic: "الحجر",
    verses_count: 99,
    pages: [262, 267],
    translated_name: { language_name: "english", name: "The Rocky Tract" },
  },
  {
    id: 16,
    revelation_place: "makkah",
    revelation_order: 70,
    bismillah_pre: true,
    name_simple: "An-Nahl",
    name_complex: "An-Naĥl",
    name_arabic: "النحل",
    verses_count: 128,
    pages: [267, 281],
    translated_name: { language_name: "english", name: "The Bee" },
  },
  {
    id: 17,
    revelation_place: "makkah",
    revelation_order: 50,
    bismillah_pre: true,
    name_simple: "Al-Isra",
    name_complex: "Al-'Isrā",
    name_arabic: "الإسراء",
    verses_count: 111,
    pages: [282, 293],
    translated_name: { language_name: "english", name: "The Night Journey" },
  },
  {
    id: 18,
    revelation_place: "makkah",
    revelation_order: 69,
    bismillah_pre: true,
    name_simple: "Al-Kahf",
    name_complex: "Al-Kahf",
    name_arabic: "الكهف",
    verses_count: 110,
    pages: [293, 304],
    translated_name: { language_name: "english", name: "The Cave" },
  },
  {
    id: 19,
    revelation_place: "makkah",
    revelation_order: 44,
    bismillah_pre: true,
    name_simple: "Maryam",
    name_complex: "Maryam",
    name_arabic: "مريم",
    verses_count: 98,
    pages: [305, 312],
    translated_name: { language_name: "english", name: "Mary" },
  },
  {
    id: 20,
    revelation_place: "makkah",
    revelation_order: 45,
    bismillah_pre: true,
    name_simple: "Taha",
    name_complex: "Ţāhā",
    name_arabic: "طه",
    verses_count: 135,
    pages: [312, 321],
    translated_name: { language_name: "english", name: "Ta-Ha" },
  },
  {
    id: 21,
    revelation_place: "makkah",
    revelation_order: 73,
    bismillah_pre: true,
    name_simple: "Al-Anbya",
    name_complex: "Al-'Anbyā",
    name_arabic: "الأنبياء",
    verses_count: 112,
    pages: [322, 331],
    translated_name: { language_name: "english", name: "The Prophets" },
  },
  {
    id: 22,
    revelation_place: "madinah",
    revelation_order: 103,
    bismillah_pre: true,
    name_simple: "Al-Hajj",
    name_complex: "Al-Ĥajj",
    name_arabic: "الحج",
    verses_count: 78,
    pages: [332, 341],
    translated_name: { language_name: "english", name: "The Pilgrimage" },
  },
  {
    id: 23,
    revelation_place: "makkah",
    revelation_order: 74,
    bismillah_pre: true,
    name_simple: "Al-Mu'minun",
    name_complex: "Al-Mu'minūn",
    name_arabic: "المؤمنون",
    verses_count: 118,
    pages: [342, 349],
    translated_name: { language_name: "english", name: "The Believers" },
  },
  {
    id: 24,
    revelation_place: "madinah",
    revelation_order: 102,
    bismillah_pre: true,
    name_simple: "An-Nur",
    name_complex: "An-Nūr",
    name_arabic: "النور",
    verses_count: 64,
    pages: [350, 359],
    translated_name: { language_name: "english", name: "The Light" },
  },
  {
    id: 25,
    revelation_place: "makkah",
    revelation_order: 42,
    bismillah_pre: true,
    name_simple: "Al-Furqan",
    name_complex: "Al-Furqān",
    name_arabic: "الفرقان",
    verses_count: 77,
    pages: [359, 366],
    translated_name: { language_name: "english", name: "The Criterion" },
  },
  {
    id: 26,
    revelation_place: "makkah",
    revelation_order: 47,
    bismillah_pre: true,
    name_simple: "Ash-Shu'ara",
    name_complex: "Ash-Shu`arā",
    name_arabic: "الشعراء",
    verses_count: 227,
    pages: [367, 376],
    translated_name: { language_name: "english", name: "The Poets" },
  },
  {
    id: 27,
    revelation_place: "makkah",
    revelation_order: 48,
    bismillah_pre: true,
    name_simple: "An-Naml",
    name_complex: "An-Naml",
    name_arabic: "النمل",
    verses_count: 93,
    pages: [377, 385],
    translated_name: { language_name: "english", name: "The Ant" },
  },
  {
    id: 28,
    revelation_place: "makkah",
    revelation_order: 49,
    bismillah_pre: true,
    name_simple: "Al-Qasas",
    name_complex: "Al-Qaşaş",
    name_arabic: "القصص",
    verses_count: 88,
    pages: [385, 396],
    translated_name: { language_name: "english", name: "The Stories" },
  },
  {
    id: 29,
    revelation_place: "makkah",
    revelation_order: 85,
    bismillah_pre: true,
    name_simple: "Al-'Ankabut",
    name_complex: "Al-`Ankabūt",
    name_arabic: "العنكبوت",
    verses_count: 69,
    pages: [396, 404],
    translated_name: { language_name: "english", name: "The Spider" },
  },
  {
    id: 30,
    revelation_place: "makkah",
    revelation_order: 84,
    bismillah_pre: true,
    name_simple: "Ar-Rum",
    name_complex: "Ar-Rūm",
    name_arabic: "الروم",
    verses_count: 60,
    pages: [404, 410],
    translated_name: { language_name: "english", name: "The Romans" },
  },
  {
    id: 31,
    revelation_place: "makkah",
    revelation_order: 57,
    bismillah_pre: true,
    name_simple: "Luqman",
    name_complex: "Luqmān",
    name_arabic: "لقمان",
    verses_count: 34,
    pages: [411, 414],
    translated_name: { language_name: "english", name: "Luqman" },
  },
  {
    id: 32,
    revelation_place: "makkah",
    revelation_order: 75,
    bismillah_pre: true,
    name_simple: "As-Sajdah",
    name_complex: "As-Sajdah",
    name_arabic: "السجدة",
    verses_count: 30,
    pages: [415, 417],
    translated_name: { language_name: "english", name: "The Prostration" },
  },
  {
    id: 33,
    revelation_place: "madinah",
    revelation_order: 90,
    bismillah_pre: true,
    name_simple: "Al-Ahzab",
    name_complex: "Al-'Aĥzāb",
    name_arabic: "الأحزاب",
    verses_count: 73,
    pages: [418, 427],
    translated_name: { language_name: "english", name: "The Combined Forces" },
  },
  {
    id: 34,
    revelation_place: "makkah",
    revelation_order: 58,
    bismillah_pre: true,
    name_simple: "Saba",
    name_complex: "Saba",
    name_arabic: "سبإ",
    verses_count: 54,
    pages: [428, 434],
    translated_name: { language_name: "english", name: "Sheba" },
  },
  {
    id: 35,
    revelation_place: "makkah",
    revelation_order: 43,
    bismillah_pre: true,
    name_simple: "Fatir",
    name_complex: "Fāţir",
    name_arabic: "فاطر",
    verses_count: 45,
    pages: [434, 440],
    translated_name: { language_name: "english", name: "Originator" },
  },
  {
    id: 36,
    revelation_place: "makkah",
    revelation_order: 41,
    bismillah_pre: true,
    name_simple: "Ya-Sin",
    name_complex: "Yā-Sīn",
    name_arabic: "يس",
    verses_count: 83,
    pages: [440, 445],
    translated_name: { language_name: "english", name: "Ya Sin" },
  },
  {
    id: 37,
    revelation_place: "makkah",
    revelation_order: 56,
    bismillah_pre: true,
    name_simple: "As-Saffat",
    name_complex: "Aş-Şāffāt",
    name_arabic: "الصافات",
    verses_count: 182,
    pages: [446, 452],
    translated_name: {
      language_name: "english",
      name: "Those who set the Ranks",
    },
  },
  {
    id: 38,
    revelation_place: "makkah",
    revelation_order: 38,
    bismillah_pre: true,
    name_simple: "Sad",
    name_complex: "Şād",
    name_arabic: "ص",
    verses_count: 88,
    pages: [453, 458],
    translated_name: { language_name: "english", name: 'The Letter "Saad"' },
  },
  {
    id: 39,
    revelation_place: "makkah",
    revelation_order: 59,
    bismillah_pre: true,
    name_simple: "Az-Zumar",
    name_complex: "Az-Zumar",
    name_arabic: "الزمر",
    verses_count: 75,
    pages: [458, 467],
    translated_name: { language_name: "english", name: "The Troops" },
  },
  {
    id: 40,
    revelation_place: "makkah",
    revelation_order: 60,
    bismillah_pre: true,
    name_simple: "Ghafir",
    name_complex: "Ghāfir",
    name_arabic: "غافر",
    verses_count: 85,
    pages: [467, 476],
    translated_name: { language_name: "english", name: "The Forgiver" },
  },
  {
    id: 41,
    revelation_place: "makkah",
    revelation_order: 61,
    bismillah_pre: true,
    name_simple: "Fussilat",
    name_complex: "Fuşşilat",
    name_arabic: "فصلت",
    verses_count: 54,
    pages: [477, 482],
    translated_name: { language_name: "english", name: "Explained in Detail" },
  },
  {
    id: 42,
    revelation_place: "makkah",
    revelation_order: 62,
    bismillah_pre: true,
    name_simple: "Ash-Shuraa",
    name_complex: "Ash-Shūraá",
    name_arabic: "الشورى",
    verses_count: 53,
    pages: [483, 489],
    translated_name: { language_name: "english", name: "The Consultation" },
  },
  {
    id: 43,
    revelation_place: "makkah",
    revelation_order: 63,
    bismillah_pre: true,
    name_simple: "Az-Zukhruf",
    name_complex: "Az-Zukhruf",
    name_arabic: "الزخرف",
    verses_count: 89,
    pages: [489, 495],
    translated_name: {
      language_name: "english",
      name: "The Ornaments of Gold",
    },
  },
  {
    id: 44,
    revelation_place: "makkah",
    revelation_order: 64,
    bismillah_pre: true,
    name_simple: "Ad-Dukhan",
    name_complex: "Ad-Dukhān",
    name_arabic: "الدخان",
    verses_count: 59,
    pages: [496, 498],
    translated_name: { language_name: "english", name: "The Smoke" },
  },
  {
    id: 45,
    revelation_place: "makkah",
    revelation_order: 65,
    bismillah_pre: true,
    name_simple: "Al-Jathiyah",
    name_complex: "Al-Jāthiyah",
    name_arabic: "الجاثية",
    verses_count: 37,
    pages: [499, 502],
    translated_name: { language_name: "english", name: "The Crouching" },
  },
  {
    id: 46,
    revelation_place: "makkah",
    revelation_order: 66,
    bismillah_pre: true,
    name_simple: "Al-Ahqaf",
    name_complex: "Al-'Aĥqāf",
    name_arabic: "الأحقاف",
    verses_count: 35,
    pages: [502, 506],
    translated_name: {
      language_name: "english",
      name: "The Wind-Curved Sandhills",
    },
  },
  {
    id: 47,
    revelation_place: "madinah",
    revelation_order: 95,
    bismillah_pre: true,
    name_simple: "Muhammad",
    name_complex: "Muĥammad",
    name_arabic: "محمد",
    verses_count: 38,
    pages: [507, 510],
    translated_name: { language_name: "english", name: "Muhammad" },
  },
  {
    id: 48,
    revelation_place: "madinah",
    revelation_order: 111,
    bismillah_pre: true,
    name_simple: "Al-Fath",
    name_complex: "Al-Fatĥ",
    name_arabic: "الفتح",
    verses_count: 29,
    pages: [511, 515],
    translated_name: { language_name: "english", name: "The Victory" },
  },
  {
    id: 49,
    revelation_place: "madinah",
    revelation_order: 106,
    bismillah_pre: true,
    name_simple: "Al-Hujurat",
    name_complex: "Al-Ĥujurāt",
    name_arabic: "الحجرات",
    verses_count: 18,
    pages: [515, 517],
    translated_name: { language_name: "english", name: "The Rooms" },
  },
  {
    id: 50,
    revelation_place: "makkah",
    revelation_order: 34,
    bismillah_pre: true,
    name_simple: "Qaf",
    name_complex: "Qāf",
    name_arabic: "ق",
    verses_count: 45,
    pages: [518, 520],
    translated_name: { language_name: "english", name: 'The Letter "Qaf"' },
  },
  {
    id: 51,
    revelation_place: "makkah",
    revelation_order: 67,
    bismillah_pre: true,
    name_simple: "Adh-Dhariyat",
    name_complex: "Adh-Dhāriyāt",
    name_arabic: "الذاريات",
    verses_count: 60,
    pages: [520, 523],
    translated_name: { language_name: "english", name: "The Winnowing Winds" },
  },
  {
    id: 52,
    revelation_place: "makkah",
    revelation_order: 76,
    bismillah_pre: true,
    name_simple: "At-Tur",
    name_complex: "Aţ-Ţūr",
    name_arabic: "الطور",
    verses_count: 49,
    pages: [523, 525],
    translated_name: { language_name: "english", name: "The Mount" },
  },
  {
    id: 53,
    revelation_place: "makkah",
    revelation_order: 23,
    bismillah_pre: true,
    name_simple: "An-Najm",
    name_complex: "An-Najm",
    name_arabic: "النجم",
    verses_count: 62,
    pages: [526, 528],
    translated_name: { language_name: "english", name: "The Star" },
  },
  {
    id: 54,
    revelation_place: "makkah",
    revelation_order: 37,
    bismillah_pre: true,
    name_simple: "Al-Qamar",
    name_complex: "Al-Qamar",
    name_arabic: "القمر",
    verses_count: 55,
    pages: [528, 531],
    translated_name: { language_name: "english", name: "The Moon" },
  },
  {
    id: 55,
    revelation_place: "madinah",
    revelation_order: 97,
    bismillah_pre: true,
    name_simple: "Ar-Rahman",
    name_complex: "Ar-Raĥmān",
    name_arabic: "الرحمن",
    verses_count: 78,
    pages: [531, 534],
    translated_name: { language_name: "english", name: "The Beneficent" },
  },
  {
    id: 56,
    revelation_place: "makkah",
    revelation_order: 46,
    bismillah_pre: true,
    name_simple: "Al-Waqi'ah",
    name_complex: "Al-Wāqi`ah",
    name_arabic: "الواقعة",
    verses_count: 96,
    pages: [534, 537],
    translated_name: { language_name: "english", name: "The Inevitable" },
  },
  {
    id: 57,
    revelation_place: "madinah",
    revelation_order: 94,
    bismillah_pre: true,
    name_simple: "Al-Hadid",
    name_complex: "Al-Ĥadīd",
    name_arabic: "الحديد",
    verses_count: 29,
    pages: [537, 541],
    translated_name: { language_name: "english", name: "The Iron" },
  },
  {
    id: 58,
    revelation_place: "madinah",
    revelation_order: 105,
    bismillah_pre: true,
    name_simple: "Al-Mujadila",
    name_complex: "Al-Mujādila",
    name_arabic: "المجادلة",
    verses_count: 22,
    pages: [542, 545],
    translated_name: { language_name: "english", name: "The Pleading Woman" },
  },
  {
    id: 59,
    revelation_place: "madinah",
    revelation_order: 101,
    bismillah_pre: true,
    name_simple: "Al-Hashr",
    name_complex: "Al-Ĥashr",
    name_arabic: "الحشر",
    verses_count: 24,
    pages: [545, 548],
    translated_name: { language_name: "english", name: "The Exile" },
  },
  {
    id: 60,
    revelation_place: "madinah",
    revelation_order: 91,
    bismillah_pre: true,
    name_simple: "Al-Mumtahanah",
    name_complex: "Al-Mumtaĥanah",
    name_arabic: "الممتحنة",
    verses_count: 13,
    pages: [549, 551],
    translated_name: {
      language_name: "english",
      name: "She that is to be examined",
    },
  },
  {
    id: 61,
    revelation_place: "madinah",
    revelation_order: 109,
    bismillah_pre: true,
    name_simple: "As-Saf",
    name_complex: "Aş-Şaf",
    name_arabic: "الصف",
    verses_count: 14,
    pages: [551, 552],
    translated_name: { language_name: "english", name: "The Ranks" },
  },
  {
    id: 62,
    revelation_place: "madinah",
    revelation_order: 110,
    bismillah_pre: true,
    name_simple: "Al-Jumu'ah",
    name_complex: "Al-Jumu`ah",
    name_arabic: "الجمعة",
    verses_count: 11,
    pages: [553, 554],
    translated_name: {
      language_name: "english",
      name: "The Congregation, Friday",
    },
  },
  {
    id: 63,
    revelation_place: "madinah",
    revelation_order: 104,
    bismillah_pre: true,
    name_simple: "Al-Munafiqun",
    name_complex: "Al-Munāfiqūn",
    name_arabic: "المنافقون",
    verses_count: 11,
    pages: [554, 555],
    translated_name: { language_name: "english", name: "The Hypocrites" },
  },
  {
    id: 64,
    revelation_place: "madinah",
    revelation_order: 108,
    bismillah_pre: true,
    name_simple: "At-Taghabun",
    name_complex: "At-Taghābun",
    name_arabic: "التغابن",
    verses_count: 18,
    pages: [556, 557],
    translated_name: {
      language_name: "english",
      name: "The Mutual Disillusion",
    },
  },
  {
    id: 65,
    revelation_place: "madinah",
    revelation_order: 99,
    bismillah_pre: true,
    name_simple: "At-Talaq",
    name_complex: "Aţ-Ţalāq",
    name_arabic: "الطلاق",
    verses_count: 12,
    pages: [558, 559],
    translated_name: { language_name: "english", name: "The Divorce" },
  },
  {
    id: 66,
    revelation_place: "madinah",
    revelation_order: 107,
    bismillah_pre: true,
    name_simple: "At-Tahrim",
    name_complex: "At-Taĥrīm",
    name_arabic: "التحريم",
    verses_count: 12,
    pages: [560, 561],
    translated_name: { language_name: "english", name: "The Prohibition" },
  },
  {
    id: 67,
    revelation_place: "makkah",
    revelation_order: 77,
    bismillah_pre: true,
    name_simple: "Al-Mulk",
    name_complex: "Al-Mulk",
    name_arabic: "الملك",
    verses_count: 30,
    pages: [562, 564],
    translated_name: { language_name: "english", name: "The Sovereignty" },
  },
  {
    id: 68,
    revelation_place: "makkah",
    revelation_order: 2,
    bismillah_pre: true,
    name_simple: "Al-Qalam",
    name_complex: "Al-Qalam",
    name_arabic: "القلم",
    verses_count: 52,
    pages: [564, 566],
    translated_name: { language_name: "english", name: "The Pen" },
  },
  {
    id: 69,
    revelation_place: "makkah",
    revelation_order: 78,
    bismillah_pre: true,
    name_simple: "Al-Haqqah",
    name_complex: "Al-Ĥāqqah",
    name_arabic: "الحاقة",
    verses_count: 52,
    pages: [566, 568],
    translated_name: { language_name: "english", name: "The Reality" },
  },
  {
    id: 70,
    revelation_place: "makkah",
    revelation_order: 79,
    bismillah_pre: true,
    name_simple: "Al-Ma'arij",
    name_complex: "Al-Ma`ārij",
    name_arabic: "المعارج",
    verses_count: 44,
    pages: [568, 570],
    translated_name: {
      language_name: "english",
      name: "The Ascending Stairways",
    },
  },
  {
    id: 71,
    revelation_place: "makkah",
    revelation_order: 71,
    bismillah_pre: true,
    name_simple: "Nuh",
    name_complex: "Nūĥ",
    name_arabic: "نوح",
    verses_count: 28,
    pages: [570, 571],
    translated_name: { language_name: "english", name: "Noah" },
  },
  {
    id: 72,
    revelation_place: "makkah",
    revelation_order: 40,
    bismillah_pre: true,
    name_simple: "Al-Jinn",
    name_complex: "Al-Jinn",
    name_arabic: "الجن",
    verses_count: 28,
    pages: [572, 573],
    translated_name: { language_name: "english", name: "The Jinn" },
  },
  {
    id: 73,
    revelation_place: "makkah",
    revelation_order: 3,
    bismillah_pre: true,
    name_simple: "Al-Muzzammil",
    name_complex: "Al-Muzzammil",
    name_arabic: "المزمل",
    verses_count: 20,
    pages: [574, 575],
    translated_name: { language_name: "english", name: "The Enshrouded One" },
  },
  {
    id: 74,
    revelation_place: "makkah",
    revelation_order: 4,
    bismillah_pre: true,
    name_simple: "Al-Muddaththir",
    name_complex: "Al-Muddaththir",
    name_arabic: "المدثر",
    verses_count: 56,
    pages: [575, 577],
    translated_name: { language_name: "english", name: "The Cloaked One" },
  },
  {
    id: 75,
    revelation_place: "makkah",
    revelation_order: 31,
    bismillah_pre: true,
    name_simple: "Al-Qiyamah",
    name_complex: "Al-Qiyāmah",
    name_arabic: "القيامة",
    verses_count: 40,
    pages: [577, 578],
    translated_name: { language_name: "english", name: "The Resurrection" },
  },
  {
    id: 76,
    revelation_place: "madinah",
    revelation_order: 98,
    bismillah_pre: true,
    name_simple: "Al-Insan",
    name_complex: "Al-'Insān",
    name_arabic: "الانسان",
    verses_count: 31,
    pages: [578, 580],
    translated_name: { language_name: "english", name: "The Man" },
  },
  {
    id: 77,
    revelation_place: "makkah",
    revelation_order: 33,
    bismillah_pre: true,
    name_simple: "Al-Mursalat",
    name_complex: "Al-Mursalāt",
    name_arabic: "المرسلات",
    verses_count: 50,
    pages: [580, 581],
    translated_name: { language_name: "english", name: "The Emissaries" },
  },
  {
    id: 78,
    revelation_place: "makkah",
    revelation_order: 80,
    bismillah_pre: true,
    name_simple: "An-Naba",
    name_complex: "An-Naba",
    name_arabic: "النبإ",
    verses_count: 40,
    pages: [582, 583],
    translated_name: { language_name: "english", name: "The Tidings" },
  },
  {
    id: 79,
    revelation_place: "makkah",
    revelation_order: 81,
    bismillah_pre: true,
    name_simple: "An-Nazi'at",
    name_complex: "An-Nāzi`āt",
    name_arabic: "النازعات",
    verses_count: 46,
    pages: [583, 584],
    translated_name: { language_name: "english", name: "Those who drag forth" },
  },
  {
    id: 80,
    revelation_place: "makkah",
    revelation_order: 24,
    bismillah_pre: true,
    name_simple: "'Abasa",
    name_complex: "`Abasa",
    name_arabic: "عبس",
    verses_count: 42,
    pages: [585, 585],
    translated_name: { language_name: "english", name: "He Frowned" },
  },
  {
    id: 81,
    revelation_place: "makkah",
    revelation_order: 7,
    bismillah_pre: true,
    name_simple: "At-Takwir",
    name_complex: "At-Takwīr",
    name_arabic: "التكوير",
    verses_count: 29,
    pages: [586, 586],
    translated_name: { language_name: "english", name: "The Overthrowing" },
  },
  {
    id: 82,
    revelation_place: "makkah",
    revelation_order: 82,
    bismillah_pre: true,
    name_simple: "Al-Infitar",
    name_complex: "Al-'Infiţār",
    name_arabic: "الإنفطار",
    verses_count: 19,
    pages: [587, 587],
    translated_name: { language_name: "english", name: "The Cleaving" },
  },
  {
    id: 83,
    revelation_place: "makkah",
    revelation_order: 86,
    bismillah_pre: true,
    name_simple: "Al-Mutaffifin",
    name_complex: "Al-Muţaffifīn",
    name_arabic: "المطففين",
    verses_count: 36,
    pages: [587, 589],
    translated_name: { language_name: "english", name: "The Defrauding" },
  },
  {
    id: 84,
    revelation_place: "makkah",
    revelation_order: 83,
    bismillah_pre: true,
    name_simple: "Al-Inshiqaq",
    name_complex: "Al-'Inshiqāq",
    name_arabic: "الإنشقاق",
    verses_count: 25,
    pages: [589, 589],
    translated_name: { language_name: "english", name: "The Sundering" },
  },
  {
    id: 85,
    revelation_place: "makkah",
    revelation_order: 27,
    bismillah_pre: true,
    name_simple: "Al-Buruj",
    name_complex: "Al-Burūj",
    name_arabic: "البروج",
    verses_count: 22,
    pages: [590, 590],
    translated_name: {
      language_name: "english",
      name: "The Mansions of the Stars",
    },
  },
  {
    id: 86,
    revelation_place: "makkah",
    revelation_order: 36,
    bismillah_pre: true,
    name_simple: "At-Tariq",
    name_complex: "Aţ-Ţāriq",
    name_arabic: "الطارق",
    verses_count: 17,
    pages: [591, 591],
    translated_name: { language_name: "english", name: "The Nightcommer" },
  },
  {
    id: 87,
    revelation_place: "makkah",
    revelation_order: 8,
    bismillah_pre: true,
    name_simple: "Al-A'la",
    name_complex: "Al-'A`lá",
    name_arabic: "الأعلى",
    verses_count: 19,
    pages: [591, 592],
    translated_name: { language_name: "english", name: "The Most High" },
  },
  {
    id: 88,
    revelation_place: "makkah",
    revelation_order: 68,
    bismillah_pre: true,
    name_simple: "Al-Ghashiyah",
    name_complex: "Al-Ghāshiyah",
    name_arabic: "الغاشية",
    verses_count: 26,
    pages: [592, 592],
    translated_name: { language_name: "english", name: "The Overwhelming" },
  },
  {
    id: 89,
    revelation_place: "makkah",
    revelation_order: 10,
    bismillah_pre: true,
    name_simple: "Al-Fajr",
    name_complex: "Al-Fajr",
    name_arabic: "الفجر",
    verses_count: 30,
    pages: [593, 594],
    translated_name: { language_name: "english", name: "The Dawn" },
  },
  {
    id: 90,
    revelation_place: "makkah",
    revelation_order: 35,
    bismillah_pre: true,
    name_simple: "Al-Balad",
    name_complex: "Al-Balad",
    name_arabic: "البلد",
    verses_count: 20,
    pages: [594, 594],
    translated_name: { language_name: "english", name: "The City" },
  },
  {
    id: 91,
    revelation_place: "makkah",
    revelation_order: 26,
    bismillah_pre: true,
    name_simple: "Ash-Shams",
    name_complex: "Ash-Shams",
    name_arabic: "الشمس",
    verses_count: 15,
    pages: [595, 595],
    translated_name: { language_name: "english", name: "The Sun" },
  },
  {
    id: 92,
    revelation_place: "makkah",
    revelation_order: 9,
    bismillah_pre: true,
    name_simple: "Al-Layl",
    name_complex: "Al-Layl",
    name_arabic: "الليل",
    verses_count: 21,
    pages: [595, 596],
    translated_name: { language_name: "english", name: "The Night" },
  },
  {
    id: 93,
    revelation_place: "makkah",
    revelation_order: 11,
    bismillah_pre: true,
    name_simple: "Ad-Duhaa",
    name_complex: "Ađ-Đuĥaá",
    name_arabic: "الضحى",
    verses_count: 11,
    pages: [596, 596],
    translated_name: { language_name: "english", name: "The Morning Hours" },
  },
  {
    id: 94,
    revelation_place: "makkah",
    revelation_order: 12,
    bismillah_pre: true,
    name_simple: "Ash-Sharh",
    name_complex: "Ash-Sharĥ",
    name_arabic: "الشرح",
    verses_count: 8,
    pages: [596, 596],
    translated_name: { language_name: "english", name: "The Relief" },
  },
  {
    id: 95,
    revelation_place: "makkah",
    revelation_order: 28,
    bismillah_pre: true,
    name_simple: "At-Tin",
    name_complex: "At-Tīn",
    name_arabic: "التين",
    verses_count: 8,
    pages: [597, 597],
    translated_name: { language_name: "english", name: "The Fig" },
  },
  {
    id: 96,
    revelation_place: "makkah",
    revelation_order: 1,
    bismillah_pre: true,
    name_simple: "Al-'Alaq",
    name_complex: "Al-`Alaq",
    name_arabic: "العلق",
    verses_count: 19,
    pages: [597, 597],
    translated_name: { language_name: "english", name: "The Clot" },
  },
  {
    id: 97,
    revelation_place: "makkah",
    revelation_order: 25,
    bismillah_pre: true,
    name_simple: "Al-Qadr",
    name_complex: "Al-Qadr",
    name_arabic: "القدر",
    verses_count: 5,
    pages: [598, 598],
    translated_name: { language_name: "english", name: "The Power" },
  },
  {
    id: 98,
    revelation_place: "madinah",
    revelation_order: 100,
    bismillah_pre: true,
    name_simple: "Al-Bayyinah",
    name_complex: "Al-Bayyinah",
    name_arabic: "البينة",
    verses_count: 8,
    pages: [598, 599],
    translated_name: { language_name: "english", name: "The Clear Proof" },
  },
  {
    id: 99,
    revelation_place: "madinah",
    revelation_order: 93,
    bismillah_pre: true,
    name_simple: "Az-Zalzalah",
    name_complex: "Az-Zalzalah",
    name_arabic: "الزلزلة",
    verses_count: 8,
    pages: [599, 599],
    translated_name: { language_name: "english", name: "The Earthquake" },
  },
  {
    id: 100,
    revelation_place: "makkah",
    revelation_order: 14,
    bismillah_pre: true,
    name_simple: "Al-'Adiyat",
    name_complex: "Al-`Ādiyāt",
    name_arabic: "العاديات",
    verses_count: 11,
    pages: [599, 600],
    translated_name: { language_name: "english", name: "The Courser" },
  },
  {
    id: 101,
    revelation_place: "makkah",
    revelation_order: 30,
    bismillah_pre: true,
    name_simple: "Al-Qari'ah",
    name_complex: "Al-Qāri`ah",
    name_arabic: "القارعة",
    verses_count: 11,
    pages: [600, 600],
    translated_name: { language_name: "english", name: "The Calamity" },
  },
  {
    id: 102,
    revelation_place: "makkah",
    revelation_order: 16,
    bismillah_pre: true,
    name_simple: "At-Takathur",
    name_complex: "At-Takāthur",
    name_arabic: "التكاثر",
    verses_count: 8,
    pages: [600, 600],
    translated_name: {
      language_name: "english",
      name: "The Rivalry in world increase",
    },
  },
  {
    id: 103,
    revelation_place: "makkah",
    revelation_order: 13,
    bismillah_pre: true,
    name_simple: "Al-'Asr",
    name_complex: "Al-`Aşr",
    name_arabic: "العصر",
    verses_count: 3,
    pages: [601, 601],
    translated_name: { language_name: "english", name: "The Declining Day" },
  },
  {
    id: 104,
    revelation_place: "makkah",
    revelation_order: 32,
    bismillah_pre: true,
    name_simple: "Al-Humazah",
    name_complex: "Al-Humazah",
    name_arabic: "الهمزة",
    verses_count: 9,
    pages: [601, 601],
    translated_name: { language_name: "english", name: "The Traducer" },
  },
  {
    id: 105,
    revelation_place: "makkah",
    revelation_order: 19,
    bismillah_pre: true,
    name_simple: "Al-Fil",
    name_complex: "Al-Fīl",
    name_arabic: "الفيل",
    verses_count: 5,
    pages: [601, 601],
    translated_name: { language_name: "english", name: "The Elephant" },
  },
  {
    id: 106,
    revelation_place: "makkah",
    revelation_order: 29,
    bismillah_pre: true,
    name_simple: "Quraysh",
    name_complex: "Quraysh",
    name_arabic: "قريش",
    verses_count: 4,
    pages: [602, 602],
    translated_name: { language_name: "english", name: "Quraysh" },
  },
  {
    id: 107,
    revelation_place: "makkah",
    revelation_order: 17,
    bismillah_pre: true,
    name_simple: "Al-Ma'un",
    name_complex: "Al-Mā`ūn",
    name_arabic: "الماعون",
    verses_count: 7,
    pages: [602, 602],
    translated_name: { language_name: "english", name: "The Small kindnesses" },
  },
  {
    id: 108,
    revelation_place: "makkah",
    revelation_order: 15,
    bismillah_pre: true,
    name_simple: "Al-Kawthar",
    name_complex: "Al-Kawthar",
    name_arabic: "الكوثر",
    verses_count: 3,
    pages: [602, 602],
    translated_name: { language_name: "english", name: "The Abundance" },
  },
  {
    id: 109,
    revelation_place: "makkah",
    revelation_order: 18,
    bismillah_pre: true,
    name_simple: "Al-Kafirun",
    name_complex: "Al-Kāfirūn",
    name_arabic: "الكافرون",
    verses_count: 6,
    pages: [603, 603],
    translated_name: { language_name: "english", name: "The Disbelievers" },
  },
  {
    id: 110,
    revelation_place: "madinah",
    revelation_order: 114,
    bismillah_pre: true,
    name_simple: "An-Nasr",
    name_complex: "An-Naşr",
    name_arabic: "النصر",
    verses_count: 3,
    pages: [603, 603],
    translated_name: { language_name: "english", name: "The Divine Support" },
  },
  {
    id: 111,
    revelation_place: "makkah",
    revelation_order: 6,
    bismillah_pre: true,
    name_simple: "Al-Masad",
    name_complex: "Al-Masad",
    name_arabic: "المسد",
    verses_count: 5,
    pages: [603, 603],
    translated_name: { language_name: "english", name: "The Palm Fiber" },
  },
  {
    id: 112,
    revelation_place: "makkah",
    revelation_order: 22,
    bismillah_pre: true,
    name_simple: "Al-Ikhlas",
    name_complex: "Al-'Ikhlāş",
    name_arabic: "الإخلاص",
    verses_count: 4,
    pages: [604, 604],
    translated_name: { language_name: "english", name: "The Sincerity" },
  },
  {
    id: 113,
    revelation_place: "makkah",
    revelation_order: 20,
    bismillah_pre: true,
    name_simple: "Al-Falaq",
    name_complex: "Al-Falaq",
    name_arabic: "الفلق",
    verses_count: 5,
    pages: [604, 604],
    translated_name: { language_name: "english", name: "The Daybreak" },
  },
  {
    id: 114,
    revelation_place: "makkah",
    revelation_order: 21,
    bismillah_pre: true,
    name_simple: "An-Nas",
    name_complex: "An-Nās",
    name_arabic: "الناس",
    verses_count: 6,
    pages: [604, 604],
    translated_name: { language_name: "english", name: "Mankind" },
  },
];
