import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-lottie",
  templateUrl: "./lottie.component.html",
  styleUrls: ["./lottie.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottieComponent implements OnInit {
  @Input() src: string;
  @Input() maxWidth: string;
  @Input() loop: boolean = true;
  options: AnimationOptions;
  styles: Partial<CSSStyleDeclaration>;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      path: this.src,
      loop: this.loop,
    };
    this.styles = {
      maxWidth: this.maxWidth,
      margin: "0 auto",
    };
  }
}
