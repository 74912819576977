import { createAction, props } from "@ngrx/store";

import * as fromModels from "../../models";
import * as fromDto from "../../dto";

/** Login Actions */
export const login = createAction(
  "[Auth] login",
  props<{ payload: fromDto.LoginUserDto }>()
);
export const loginSuccess = createAction(
  "[Auth] login success",
  props<{ payload: { user: fromModels.IUser; email: string } }>()
);
export const loginFailure = createAction(
  "[Auth] login failure",
  // errors: {'User': string}
  props<{ payload: IError }>()
);

/** Signup Actions */
export const signup = createAction(
  "[Auth] signup",
  props<{ payload: fromDto.CreateUserDto }>()
);
export const signupSuccess = createAction(
  "[Auth] signup success",
  props<{ payload: fromModels.IUser }>()
);
export const signupFailure = createAction(
  "[Auth] signup failure",
  props<{ payload: IError }>()
);

/** Signup with invitation Actions */
export const signupWithInvitation = createAction(
  "[Auth] signup with invitation",
  props<{ payload: fromDto.CreateUserDto }>()
);
export const signupWithInvitationSuccess = createAction(
  "[Auth] signup with invitation success",
  props<{ payload: fromModels.IUser }>()
);
export const signupWithInvitationFailure = createAction(
  "[Auth] signup with invitation failure",
  props<{ payload: IError }>()
);

/** Logout Actions */
export const logout = createAction("[Auth] logout");

// Forget Password Actions
export const forgetPassword = createAction(
  "[Auth] forget password",
  props<{ payload: fromDto.ForgetPasswordDto }>()
);
export const forgetPasswordSuccess = createAction(
  "[Auth] forget password success",
  /** payload refers to email to redirect link with query params has this email */
  props<{ payload: string }>()
);
export const forgetPasswordFailure = createAction(
  "[Auth] forget password failure",
  props<{ payload: IError }>()
);

// Reset Password Actions
export const resetPassword = createAction(
  "[Auth] reset password",
  props<{
    payload: {
      dto: fromDto.ResetPasswordDto;
      userType: fromModels.userType;
    };
  }>()
);
export const resetPasswordSuccess = createAction(
  "[Auth] reset password success",
  props<{
    payload: {
      userType: fromModels.userType;
    };
  }>()
);
export const resetPasswordFailure = createAction(
  "[Auth] reset password failure",
  props<{ payload: IError }>()
);

// Verify email Actions
export const verify = createAction(
  "[Auth] verify",
  props<{ payload: fromDto.VerifyUserDto }>()
);
export const verifySuccess = createAction(
  "[Auth] verify success",
  props<{ payload: fromModels.IUser }>()
);
export const verifyFailure = createAction(
  "[Auth] verify failure",
  props<{ payload: IError }>()
);

export const loadUser = createAction("[Auth] load user");
export const loadUserSuccess = createAction(
  "[Auth] load user success",
  props<{ payload: fromModels.IUser }>()
);
export const loadUserFailure = createAction(
  "[Auth] load user failure",
  /** not added error payload in swagger */
  props<{ payload: IError }>()
);

export const updateUser = createAction(
  "[Auth] update user",
  props<{ payload: fromDto.UpdateUserDto }>()
);
export const updateUserSuccess = createAction(
  "[Auth] update user success",
  props<{ payload: fromModels.IUser }>()
);
export const updateUserFailure = createAction(
  "[Auth] update user failure",
  props<{ payload: IError }>()
);

// resend verification code Actions

export const resendVerificationCode = createAction(
  "[Auth] resend verification code",
  props<{
    payload: {
      email: string;
    };
  }>()
);
export const resendVerificationCodeSuccess = createAction(
  "[Auth] resend verification code success"
);
export const resendVerificationCodeFailure = createAction(
  "[Auth] resend verification code failure",
  props<{ payload: IError }>()
);

/** Logout organization Actions */
export const logoutOrganization = createAction(
  "[Auth] logout organization",
  props<{ payload: string }>()
);

export const logoutOrganizationSuccess = createAction(
  "[Auth] logout organization success"
);

export const logoutOrganizationFailure = createAction(
  "[Auth] logout organization failure",
  props<{ payload: IError }>()
);

// Verify update email Actions
export const verifyUpdateEmail = createAction(
  "[Auth] verify update email",
  props<{ payload: fromDto.VerifyUserDto }>()
);
export const verifyUpdateEmailSuccess = createAction(
  "[Auth] verify verify update email success",
  props<{ payload: fromModels.IUser }>()
);
export const verifyUpdateEmailFailure = createAction(
  "[Auth] verify verify update email failure",
  props<{ payload: IError }>()
);

// resend update email verification code Actions

export const resendUpdateEmailVerificationCode = createAction(
  "[Auth] resend update email verification code",
  props<{
    payload: {
      email: string;
    };
  }>()
);
export const resendUpdateEmailVerificationCodeSuccess = createAction(
  "[Auth] resend update email verification code success"
);
export const resendUpdateEmailVerificationCodeFailure = createAction(
  "[Auth] resend update email verification code failure",
  props<{ payload: IError }>()
);
