import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private afMessaging: AngularFireMessaging) {}

  requestPermission() {
    this.afMessaging.requestToken.subscribe(
      (token) => {},
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  receiveMessage() {
    return this.afMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(JSON.stringify(payload));
    });
  }

  requestToken() {
    return this.afMessaging.requestToken;
  }

  // deleteToken() {
  //   return this.afMessaging.getToken
  //     .pipe(mergeMap((token) => this.afMessaging.deleteToken(token)))
  //     .subscribe();
  // }
}
