import { Action, createReducer, on } from "@ngrx/store";

import * as fromRootModels from "../../models";
import * as fromActions from "../../store/actions";

export interface IAuthState {
  loginPending: boolean;
  loginError: IError;

  signupPending: boolean;
  signupError: IError;

  // signupWithInvitationPending: boolean;
  // signupWithInvitationError: IError;

  forgetPasswordPending: boolean;
  forgetPasswordError: IError;

  resetPasswordPending: boolean;
  resetPasswordError: IError;

  verifyPending: boolean;
  verifySucess: boolean;
  verifyError: IError;

  userPending: boolean;
  userError: IError;
  user: fromRootModels.IUser;

  updatePending: boolean;
  updateError: IError;

  logoutPending: boolean;
  logoutError: IError;

  verifyUpdateEmailPending: boolean;
  verifyUpdateEmailError: IError;
}

export const initialState: IAuthState = {
  loginPending: false,
  loginError: undefined,

  signupPending: false,
  signupError: undefined,

  // signupWithInvitationPending: false,
  // signupWithInvitationError: undefined,

  forgetPasswordPending: false,
  forgetPasswordError: undefined,

  resetPasswordPending: false,
  resetPasswordError: undefined,

  verifyPending: false,
  verifySucess: undefined,
  verifyError: undefined,

  userPending: false,
  userError: undefined,
  user: undefined,

  updatePending: false,
  updateError: undefined,

  logoutPending: false,
  logoutError: undefined,

  verifyUpdateEmailPending: false,
  verifyUpdateEmailError: undefined,
};

export const authReducer = createReducer(
  initialState,

  // Handle login actions
  on(fromActions.login, (state) => ({
    ...state,
    loginPending: true,
    loginError: undefined,
  })),
  on(fromActions.loginSuccess, (state, { payload }) => ({
    ...state,
    loginPending: false,
    loginError: undefined,
    user: payload.user,
    userError: undefined,
  })),
  on(fromActions.loginFailure, (state, { payload }) => ({
    ...state,
    loginPending: false,
    loginError: payload,
  })),

  // Handle signup actions
  on(fromActions.signup, (state) => ({
    ...state,

    signupPending: true,
    signupError: undefined,
  })),
  on(fromActions.signupSuccess, (state, { payload }) => ({
    ...state,
    signupPending: false,
    user: payload,
    userError: undefined,
  })),
  on(fromActions.signupFailure, (state, { payload }) => ({
    ...state,
    signupPending: false,
    signupError: payload,
  })),

  // Handle signup with invitation actions
  on(fromActions.signupWithInvitation, (state) => ({
    ...state,

    signupPending: true,
    signupError: undefined,
  })),
  on(fromActions.signupWithInvitationSuccess, (state, { payload }) => ({
    ...state,
    signupPending: false,
    user: payload,
  })),
  on(fromActions.signupWithInvitationFailure, (state, { payload }) => ({
    ...state,
    signupPending: false,
    signupError: payload,
  })),

  // Handle forget password actions
  on(fromActions.forgetPassword, (state) => ({
    ...state,
    forgetPasswordPending: true,
    forgetPasswordError: undefined,
  })),
  on(fromActions.forgetPasswordSuccess, (state) => ({
    ...state,
    forgetPasswordPending: false,
    forgetPasswordError: undefined,
  })),
  on(fromActions.forgetPasswordFailure, (state, { payload }) => ({
    ...state,
    forgetPasswordPending: false,
    forgetPasswordError: payload,
  })),

  // Handle reset password actions
  on(fromActions.resetPassword, (state) => ({
    ...state,
    resetPasswordPending: true,
    resetPasswordError: undefined,
  })),
  on(fromActions.resetPasswordSuccess, (state) => ({
    ...state,
    resetPasswordPending: false,
    resetPasswordError: undefined,
  })),
  on(fromActions.resetPasswordFailure, (state, { payload }) => ({
    ...state,
    resetPasswordPending: false,
    resetPasswordError: payload,
  })),

  // Handle verify
  on(fromActions.verify, (state) => ({
    ...state,
    verifyPending: true,
    verifySucess: undefined,
    verifyError: undefined,
  })),
  on(fromActions.verifySuccess, (state, { payload }) => ({
    ...state,
    verifyPending: false,
    verifySucess: true,
    verifyError: undefined,
    user: payload,
  })),
  on(fromActions.verifyFailure, (state, { payload }) => ({
    ...state,
    verifyPending: false,
    verifySucess: false,
    verifyError: payload,
  })),

  on(fromActions.loadUser, (state) => ({
    ...state,
    userPending: true,
  })),
  on(fromActions.loadUserSuccess, (state, { payload }) => ({
    ...state,
    userPending: false,
    user: payload,
    userError: undefined,
  })),
  on(fromActions.loadUserFailure, (state, { payload }) => ({
    ...state,
    userPending: false,
    userError: payload,
  })),

  on(fromActions.updateUser, (state) => ({
    ...state,
    updatePending: true,
    updateError: undefined,
  })),
  on(fromActions.updateUserSuccess, (state, { payload }) => ({
    ...state,
    updatePending: false,
    user: payload,
  })),
  on(fromActions.updateUserFailure, (state, { payload }) => ({
    ...state,
    updatePending: false,
    updateError: payload,
  })),

  // Handle logout organization actions
  on(fromActions.logoutOrganization, (state) => ({
    ...state,
    logoutPending: true,
    logoutError: undefined,
  })),
  on(fromActions.logoutOrganizationSuccess, (state) => ({
    ...state,
    logoutPending: false,
  })),
  on(fromActions.logoutOrganizationFailure, (state, { payload }) => ({
    ...state,
    logoutPending: false,
    logoutError: payload,
  })),

  // Handle verify update email
  on(fromActions.verifyUpdateEmail, (state) => ({
    ...state,
    verifyUpdateEmailPending: true,
    verifyUpdateEmailError: undefined,
  })),
  on(fromActions.verifyUpdateEmailSuccess, (state, { payload }) => ({
    ...state,
    verifyUpdateEmailPending: false,
    verifyUpdateEmailError: undefined,
    user: payload,
  })),
  on(fromActions.verifyUpdateEmailFailure, (state, { payload }) => ({
    ...state,
    verifyUpdateEmailPending: false,
    verifyUpdateEmailError: payload,
  }))
);

export function reducer(
  state: IAuthState | undefined,
  action: Action
): IAuthState {
  return authReducer(state, action);
}

// Login selectors
export const getLoginPending = (state: IAuthState) => state.loginPending;
export const getLoginError = (state: IAuthState) => state.loginError;

// student Signup selectors
export const getSignupPending = (state: IAuthState) => state.signupPending;
export const getSignupError = (state: IAuthState) => state.signupError;

// teacher Signup selectors
// export const getSignupWithInvitationPending = (state: IAuthState) =>
//   state.signupWithInvitationPending;
// export const getSignupWithInvitationError = (state: IAuthState) =>
//   state.signupWithInvitationError;

export const getUser = (state: IAuthState) => state.user;
export const getUserPending = (state: IAuthState) => state.userPending;
export const getUserError = (state: IAuthState) => state.userError;

// Forget password selectors
export const getForgetPasswordPending = (state: IAuthState) =>
  state.forgetPasswordPending;
export const getForgetPasswordError = (state: IAuthState) =>
  state.forgetPasswordError;

// Reset password selectors
export const getResetPasswordPending = (state: IAuthState) =>
  state.resetPasswordPending;
export const getResetPasswordError = (state: IAuthState) =>
  state.resetPasswordError;

// Verify selectors
export const getVerifyPending = (state: IAuthState) => state.verifyPending;
export const getVerifySucess = (state: IAuthState) => state.verifySucess;
export const getVerifyError = (state: IAuthState) => state.verifyError;

// update selectors

export const getUpdatePending = (state: IAuthState) => state.updatePending;
export const getUpdateError = (state: IAuthState) => state.updateError;

// logout selectors

export const getLogoutPending = (state: IAuthState) => state.logoutPending;
export const getLogoutError = (state: IAuthState) => state.logoutError;

// Verify update email selectors
export const getVerifyUpdateEmailPending = (state: IAuthState) =>
  state.verifyUpdateEmailPending;
export const getVerifyUpdateEmailError = (state: IAuthState) =>
  state.verifyUpdateEmailError;
