import { createAction, props } from "@ngrx/store";

export const loadFirebaseToken = createAction(
  "[Notification] request firebase token"
);
export const loadFirebaseTokenSuccess = createAction(
  "[Notification] request firebase token success",
  props<{ payload: string }>()
);
export const loadFirebaseTokenFailure = createAction(
  "[Notification] request firebase token failure",
  props<{ payload: IError }>()
);
