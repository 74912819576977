import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import * as fromServices from "../services";

/**
 * check if user is not authenticated navigate to /auth/login with queryParams having current url
 * and pass it if authenticated
 *
 * @export
 * @class AuthGuardService
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private auth: fromServices.AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(["/auth/login"], {
        queryParams: { redirectURL: state.url },
      });
      return false;
    }
    return true;
  }
}
