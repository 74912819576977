import { createSelector } from "@ngrx/store";

import * as fromRoot from "../reducers";
import * as fromAuthReducer from "../reducers/auth.reducer";

// Login
export const getLoginPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLoginPending
);

export const getLoginError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLoginError
);

// Signup
export const getSignupPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSignupPending
);

export const getSignupError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSignupError
);

// //  Signup with invitation
// export const getSignupWithInvitationPending = createSelector(
//   fromRoot.getAuthState,
//   fromAuthReducer.getSignupWithInvitationPending
// );

// export const getSignupWithInvitationError = createSelector(
//   fromRoot.getAuthState,
//   fromAuthReducer.getSignupWithInvitationError
// );

export const getUserPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUserPending
);
export const getUserError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUserError
);
export const getCurrentUser = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUser
);

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser && currentUser.id
);

// Forget password
export const getForgetPasswordPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getForgetPasswordPending
);

export const getForgetPasswordError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getForgetPasswordError
);

// Reset password
export const getResetPasswordPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResetPasswordPending
);

export const getResetPasswordError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResetPasswordError
);

// Verify
export const getVerifyPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyPending
);

export const getVerifyError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyError
);
export const getVerifySuccess = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifySucess
);

//update
export const getUpdatePending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUpdatePending
);

export const getUpdateError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUpdateError
);

// Logout
export const getLogoutPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLogoutPending
);

export const getLogoutError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLogoutError
);

// Verify update email
export const getVerifyUpdateEmailPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyUpdateEmailPending
);

export const getVerifyUpdateEmailError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyUpdateEmailError
);
