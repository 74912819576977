export const environment = {
  production: true,
  apiURL: "https://qupil-release-app-itwdaqrulq-oa.a.run.app/api",
  appId: "880e00779d454230b85023256c6b9bad",
  createLectureFeatureFlag: true,
  firebase: {
    apiKey: "AIzaSyDz7JfbialMrCcMnVExt8in91Ka5TS8NoQ",
    authDomain: "qupil-345111.firebaseapp.com",
    databaseURL:
      "https://qupil-345111-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "qupil-345111",
    storageBucket: "qupil-345111.appspot.com",
    messagingSenderId: "60148088351",
    appId: "1:60148088351:web:469a3f4f75cf598d665f2a",
    measurementId: "G-6YX762CE86",
  },
  intercomAppId: "shbk4qqg",
  intercomSecretKey: "DzC51VWC0d8kH2edzRv0nQm2WGpExkwH8a8fT95o",
};
