export const Quran_Juz = [
  { name: "الجُزْءُ الأَوَّلُ", pages: { start: 1, end: 21 } },
  { name: "الجُزْءُ الثَّانِي", pages: { start: 22, end: 41 } },
  { name: "الجُزْءُ الثّالِثُ", pages: { start: 42, end: 61 } },
  { name: "الجُزْءُ الرّابِعُ", pages: { start: 62, end: 81 } },
  { name: "الجُزْءُ الخامِسُ", pages: { start: 82, end: 101 } },
  { name: "الجُزْءُ السّادِسُ", pages: { start: 102, end: 121 } },
  { name: "الجُزْءُ السّابِعُ", pages: { start: 122, end: 141 } },
  { name: "الجُزْءُ الثّامِنُ", pages: { start: 142, end: 161 } },
  { name: "الجُزْءُ التّاسِعُ", pages: { start: 162, end: 181 } },
  { name: "الجُزْءُ العاشِرُ", pages: { start: 182, end: 201 } },
  { name: "الجُزْءُ اَلْحَادِيَ عَشَرَ", pages: { start: 202, end: 221 } },
  { name: "الجُزْءُ الثَّانِيَ عَشَرَ", pages: { start: 222, end: 241 } },
  { name: "الجُزْءُ الثّالِثَ عَشَرَ", pages: { start: 242, end: 261 } },
  { name: "الجُزْءُ الرّابِعُ عَشَرَ", pages: { start: 262, end: 281 } },
  { name: "الجُزْءُ الخامِسَ عَشَرَ", pages: { start: 282, end: 301 } },
  { name: "الجُزْءُ السّادِسُ عَشَرَ ", pages: { start: 302, end: 321 } },
  { name: "الجُزْءُ السّابِعُ عَشَرَ", pages: { start: 322, end: 341 } },
  { name: "الجُزْءُ الثّامِنُ عَشَرَ", pages: { start: 342, end: 361 } },
  { name: "الجُزْءُ التّاسِعُ عَشَرَ", pages: { start: 362, end: 381 } },
  { name: "الجُزْءُ العِشْرُونَ", pages: { start: 382, end: 401 } },
  {
    name: "الجُزْءُ الْحَادِي وَالعِشْرُونَ",
    pages: { start: 402, end: 421 },
  },
  {
    name: "الجُزْءُ الثَّانِي وَالعِشْرُونَ",
    pages: { start: 422, end: 441 },
  },
  {
    name: "الجُزْءُ الثّالِثُ وَالعِشْرُونَ",
    pages: { start: 442, end: 461 },
  },
  {
    name: "الجُزْءُ الرّابِعُ وَالعِشْرُونَ",
    pages: { start: 462, end: 481 },
  },
  {
    name: "الجُزْءُ الخامِسُ وَالعِشْرُونَ",
    pages: { start: 482, end: 501 },
  },
  {
    name: "الجُزْءُ السّادِسُ وَالعِشْرُونَ",
    pages: { start: 502, end: 521 },
  },
  {
    name: "الجُزْءُ السّابِعُ وَالعِشْرُونَ",
    pages: { start: 522, end: 541 },
  },
  {
    name: "الجُزْءُ الثّامِنُ وَالعِشْرُونَ",
    pages: { start: 542, end: 561 },
  },
  {
    name: "الجُزْءُ التّاسِعُ وَالعِشْرُونَ",
    pages: { start: 562, end: 581 },
  },
  { name: "الجُزْءُ الثَّلَاثُونَ", pages: { start: 582, end: 604 } },
];
