import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";

export class TranslateLSLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return Observable.create((observer) => {
      let translations = localStorage.getItem(lang);
      if (translations) {
        observer.next(JSON.parse(translations));
      }
    });
  }
}
