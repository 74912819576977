import { AuthGuardService } from "./auth.guard";
import { CloseAuthGuardService } from "./close-auth.guard";
import { SignupAuthGuardService } from "./signup-auth.guard";
import { SuperAdminGuard } from "./super-admin.guard";
import { TeacherGuard } from "./teacher.guard";
import { MemberGuard } from "./member.guard";
import { CRMGuard } from "./crm.guard";
export const GUARDS = [
  AuthGuardService,
  CloseAuthGuardService,
  SignupAuthGuardService,
  SuperAdminGuard,
  CRMGuard,
  TeacherGuard,
  MemberGuard,
];

export * from "./auth.guard";
export * from "./close-auth.guard";
export * from "./signup-auth.guard";
export * from "./super-admin.guard";
export * from "./teacher.guard";
export * from "./member.guard";
export * from "./crm.guard";
