import { JwtHelperService } from "@auth0/angular-jwt";
import { createSelector } from "@ngrx/store";

import * as fromRoot from "../reducers";

export const getParamsByRouter = createSelector(
  fromRoot.getRouterState,
  (router: any) => {
    return router && router.state && router.state.params;
  }
);

export const getQueryParamsByRouter = createSelector(
  fromRoot.getRouterState,
  (router: any) => {
    return router && router.state?.queryParams;
  }
);

/** this selector used in guard to check pass to load signup module or not */
export const getSignUpParams = createSelector(
  getQueryParamsByRouter,
  (params) => {
    try {
      const signupToken = params && params.token;
      const email = new JwtHelperService().decodeToken(signupToken).email;

      return email ? signupToken : false;
    } catch (error) {
      return false;
    }
  }
);

/** update selector to guard to use user attr rather than email */
export const getSignUpUserParams = createSelector(
  getQueryParamsByRouter,
  (params) => {
    try {
      const signupToken = params && params.token;
      const user = new JwtHelperService().decodeToken(signupToken).user;

      return user ? signupToken : false;
    } catch (error) {
      return false;
    }
  }
);

/** used in signup component */
export const getEmailFromToken = createSelector(
  getSignUpParams,
  (param) => param && new JwtHelperService().decodeToken(param)?.email
);

export const getQueryParamsEmailAddress = createSelector(
  getQueryParamsByRouter,
  (queryParam) => queryParam && queryParam.emailAddress
);
