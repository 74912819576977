import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";

import * as fromServices from "../services";
import { RedirectService } from "../services/redirect.service";

/**
 * check if user authenticated navigate user to the following url in queryParams
 * pass it if user isn't authenticated

 */
@Injectable({
  providedIn: "root",
})
export class CloseAuthGuardService implements CanActivate {
  constructor(
    private auth: fromServices.AuthService,
    private redirectService: RedirectService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.auth.isAuthenticated()) {
      this.redirectService.redirect(route.queryParams.redirectURL);
      return false;
    }
    return true;
  }
}
