import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import * as fromGuards from "./guards";
import * as fromServices from "./services";
import * as fromStore from "./store";
import { CustomSerializer } from "./utils/custom-serializer";
import { LottieAnimationModule } from "./shared-modules/lottie-animation/lottie-animation.module";
import {
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateLSLoader } from "./models/i18n";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLSLoader,
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    LottieAnimationModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,

    StoreModule.forRoot(fromStore.reducers, {
      metaReducers: [fromStore.clearState],
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),

    EffectsModule.forRoot(fromStore.EFFECTS),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    DragDropModule,
  ],
  providers: [
    ...fromGuards.GUARDS,
    ...fromServices.SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: fromServices.TokenInterceptor,
      multi: true,
    },
    ScreenTrackingService,
    UserTrackingService,
    TranslateService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: fromServices.TimeZoneInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
