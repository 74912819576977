import { createAction, props } from "@ngrx/store";
import * as fromDto from "../../dto";
import * as fromModels from "../../models";

// load teacher actions

export const loadTeacher = createAction(
  "[Teacher] load teacher",
  props<{
    payload: {
      organizationId: string;
    };
  }>()
);
export const loadTeacherSuccess = createAction(
  "[Teacher] load teacher success",
  props<{ payload: fromModels.ITeacherApplication }>()
);

export const loadTeacherFailure = createAction(
  "[Teacher] load teacher failure",
  props<{ payload: IError }>()
);

// update teacher actions

export const updateTeacher = createAction(
  "[Teacher] update teacher",
  props<{
    payload: {
      organizationId: string;
      updateDto: fromDto.UpdateTeacherApplicationDto;
    };
  }>()
);
export const updateTeacherSuccess = createAction(
  "[Teacher] update teacher success",
  props<{ payload: fromModels.ITeacherApplication }>()
);

export const updateTeacherFailure = createAction(
  "[Teacher] update teacher failure",
  props<{ payload: IError }>()
);
