import { createSelector } from "@ngrx/store";

import * as fromRoot from "../reducers";
import * as fromNotificationReducer from "../reducers/notification.reducer";

export const getNotificationPending = createSelector(
  fromRoot.getNotificationState,
  fromNotificationReducer.getNotificationPending
);

export const getNotificationError = createSelector(
  fromRoot.getNotificationState,
  fromNotificationReducer.getNotificationError
);

export const getNotificationFirebaseToken = createSelector(
  fromRoot.getNotificationState,
  fromNotificationReducer.getNotificationFirebaseToken
);

export const getNotificationFirebaseTokenCleared = createSelector(
  getNotificationPending,
  getNotificationFirebaseToken,
  (pending, firebaseToken) => !pending && !firebaseToken
);
