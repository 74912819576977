import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";

import * as fromStore from "../store";
import * as fromActions from "../store/actions";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  constructor(
    public auth: AuthService,
    private store: Store<fromStore.IRootState>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.auth.getAccessToken();

    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          this.auth.getRefreshToken()
        ) {
          return this.handle401Error(request, next);
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    //

    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  /**
   * Handle refresh token
   *
   * @private
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenInterceptor
   */
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap(({ accessToken }) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(accessToken);
          return next.handle(this.addToken(request, accessToken));
        }),
        catchError((err) => {
          this.store.dispatch(fromActions.logoutOrganizationSuccess());
          return throwError(err);
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((accessToken) => {
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }
}
