import { Action, createReducer, on } from "@ngrx/store";
import * as fromActions from "../actions";
import * as fromModels from "../../models";
export interface ITeacherState {
  loading: boolean;
  error: IError;
  teacher: fromModels.ITeacherApplication;

  updatePending: boolean;
  updateError: IError;
}

export const initialState: ITeacherState = {
  loading: false,
  error: undefined,
  teacher: undefined,

  updatePending: false,
  updateError: undefined,
};

export const teacherOnboardingReducer = createReducer(
  initialState,

  // load teacher actions
  on(fromActions.loadTeacher, (state) => ({
    ...state,
    loading: true,
  })),

  on(fromActions.loadTeacherSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    teacher: payload,
  })),

  on(fromActions.loadTeacherFailure, (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
  })),

  // update teacher actions
  on(fromActions.updateTeacher, (state) => ({
    ...state,
    updatePending: true,
    updateError: undefined,
  })),

  on(fromActions.updateTeacherSuccess, (state, { payload }) => ({
    ...state,
    updatePending: false,
    teacher: payload,
  })),

  on(fromActions.updateTeacherFailure, (state, { payload }) => ({
    ...state,
    updatePending: false,
    updateError: payload,
  }))
);

export function reducer(
  state: ITeacherState | undefined,
  action: Action
): ITeacherState {
  return teacherOnboardingReducer(state, action);
}

// load selectors
export const getTeacherLoading = (state: ITeacherState) => state.loading;

export const getTeacherTeacher = (state: ITeacherState) => state.teacher;
export const getTeacherError = (state: ITeacherState) => state.error;

// update selectors
export const getTeacherUpdatePending = (state: ITeacherState) =>
  state.updatePending;
export const getTeacherUpdateError = (state: ITeacherState) =>
  state.updateError;
