import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LottieModule, LottieCacheModule } from "ngx-lottie";
import { playerFactory } from "src/app/utils/lottie-player-factory";

import * as fromContainers from "./containers";

@NgModule({
  declarations: [...fromContainers.CONTAINERS],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
  ],
  exports: [...fromContainers.CONTAINERS],
})
export class LottieAnimationModule {}
