import { createSelector } from "@ngrx/store";

import * as fromTeacherReducer from "../reducers/teacher.reducer";
import * as fromRoot from "../reducers";

// load teacher selectors
export const getTeacherLoading = createSelector(
  fromRoot.getTeacherState,
  fromTeacherReducer.getTeacherLoading
);

export const getTeacherTeacher = createSelector(
  fromRoot.getTeacherState,
  fromTeacherReducer.getTeacherTeacher
);

export const getTeacherError = createSelector(
  fromRoot.getTeacherState,
  fromTeacherReducer.getTeacherError
);

//update teacher selectors

export const getTeacherUpdatePending = createSelector(
  fromRoot.getTeacherState,
  fromTeacherReducer.getTeacherUpdatePending
);

export const getTeacherUpdateError = createSelector(
  fromRoot.getTeacherState,
  fromTeacherReducer.getTeacherUpdateError
);
