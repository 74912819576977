// you can add your globals here

import * as fromServices from "../services";
let authService: fromServices.AuthService;

export const ORGANIZATION_DEFAULT_IMAGE =
  "assets/defaults/company-placeholder.jpg";
export const DEFAULT_PROFILE_IMAGE = "assets/defaults/default-user.png";
export const DEFAULT_UPLOAD_IMAGE = "assets/defaults/upload-image.jpg";
export const DEFAULT_MAN_IMAGE = "assets/defaults/man.png";
export const DEFAULT_WOMAN_IMAGE = "assets/defaults/woman.png";

// const organizationId

export const ORGANIZATION_ID =
  localStorage.getItem("organizationId") || "000000000000000000000001";
export const QUPIL_ORGANIZATION_ID = "000000000000000000000001";
export const VCFO_ORGANIZATION_ID = "000000000000000001000000";

// lecture types
export const LECTURE_TYPES = {
  REGULAR: "REGULAR",
  FAMILY_SAVER: "FAMILY_SAVER",
  MAQRAAH: "MAQRAAH",
  HALAKAH: "HALAKAH",
  INSTANT: "INSTANT",
};

// languages
export const VCFO_LANGUAGE_EN = `{"Teacher":"Consultant","Student":"Client","Lecture":"Consultation","Lesson":"Session","Igaza":"Certifications","Halaka":"Course","Maqraah":"Webinar","Instant":"Instant","Regular":"30 Minute","Family_Saver":"60 Minute","Juz":"Topic","Surah":"Sub-topic","Parent":"Company"}`;
export const QUPIL_LANGUAGE_EN = `{"Teacher":"Teacher","Student":"Student","Lecture":"Lecture","Lesson":"Lesson","Igaza":"Igaza","Halaka":"Halaka","Maqraah":"Maqra'ah","Instant":"Instant","Regular":"Regular","Family_Saver":"Family Saver","Juz":"Juz'","Surah":"Surah","Parent":"Parent"}`;
