import * as fromRouter from "@ngrx/router-store";
import { createFeatureSelector } from "@ngrx/store";

import * as fromModels from "../../models";
import * as fromAuthReducer from "./auth.reducer";
import * as fromNotificationReducer from "./notification.reducer";
import * as fromTeacherReducer from "./teacher.reducer";

export interface IRootState {
  auth: fromAuthReducer.IAuthState;
  router: fromRouter.RouterReducerState<fromModels.RouterStateUrl>;
  notification: fromNotificationReducer.INotificationState;
  teacher: fromTeacherReducer.ITeacherState;
}

export const reducers = {
  auth: fromAuthReducer.reducer,
  router: fromRouter.routerReducer,
  notification: fromNotificationReducer.reducer,
  teacher: fromTeacherReducer.reducer,
};

export const getAuthState =
  createFeatureSelector<fromAuthReducer.IAuthState>("auth");

export const getRouterState =
  createFeatureSelector<fromModels.RouterStateUrl>("router");

export const getNotificationState =
  createFeatureSelector<fromNotificationReducer.INotificationState>(
    "notification"
  );

export const getTeacherState =
  createFeatureSelector<fromTeacherReducer.ITeacherState>("teacher");

export * from "./clear-state.reducer";
