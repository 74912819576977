import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";

import * as fromGuards from "./guards";

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: "always",
};

const routes: Routes = [
  // { path: '', component: AppComponent },
  {
    path: "auth",
    loadChildren: () =>
      import("./views/auth/auth.module").then((module) => module.AuthModule),
  },
  {
    path: "crm",
    loadChildren: () =>
      import("./views/crm/crm.module").then((module) => module.CRMModule),
    canActivate: [fromGuards.AuthGuardService, fromGuards.CRMGuard],
  },
  {
    path: "super-admin",
    loadChildren: () =>
      import("./views/super-admin/super-admin.module").then(
        (module) => module.SuperAdminModule
      ),
    canActivate: [fromGuards.AuthGuardService, fromGuards.SuperAdminGuard],
  },
  {
    path: "teacher",
    loadChildren: () =>
      import("./views/teacher/teacher.module").then(
        (module) => module.TeacherModule
      ),
    canActivate: [fromGuards.AuthGuardService, fromGuards.TeacherGuard],
  },
  {
    path: "member",
    loadChildren: () =>
      import("./views/member/member.module").then(
        (module) => module.MemberModule
      ),
    canActivate: [fromGuards.MemberGuard],
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./views/privacy-policy/privacy-policy.module").then(
        (module) => module.PrivacyPolicyModule
      ),
  },
  { path: "", redirectTo: "super-admin", pathMatch: "full" },
  {
    path: "**",
    loadChildren: () =>
      import("./views/not-found/not-found.module").then(
        (module) => module.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
