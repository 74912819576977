import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import * as fromStore from "../store";
import { map } from "rxjs/operators";
import * as fromActions from "../store/actions";
import * as fromServices from "../services";

@Injectable({
  providedIn: "root",
})
export class SignupAuthGuardService implements CanActivate {
  constructor(
    private store: Store<fromStore.IRootState>,
    private router: Router,
    private authService: fromServices.AuthService,
    private redirectService: fromServices.RedirectService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getSignUpUserParams),
      map((user) => {
        // if there is an email token and not a user

        if (!user && route.queryParams.token) {
          this.authService.removeRefreshToken();
          this.authService.removeAccessToken();
          return true;
        }

        // else if there is user try navigating to sign up
        else {
          // if he is authenticated (has access token) then redirect him to the last active url
          if (this.authService.isAuthenticated()) {
            this.redirectService.redirect(route.queryParams.redirectURL);
            return false;
          }

          // if he is not authenticated (no access token)
          else {
            return true;
          }
        }
      })
    );
  }
}
