import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import * as fromModels from "../models";
import * as fromDto from "../dto";
@Injectable()
export class TeacherService {
  constructor(private http: HttpClient) {}

  loadTeacher(
    organizationId: string
  ): Observable<fromModels.ITeacherApplication> {
    return this.http.get<fromModels.ITeacherApplication>(
      `${environment.apiURL}/organizations/${organizationId}/teachers/me/draft`
    );
  }

  updateTeacher({
    updateDto,
    organizationId,
  }: {
    updateDto: fromDto.UpdateTeacherApplicationDto;
    organizationId: string;
  }): Observable<fromModels.ITeacherApplication> {
    return this.http.patch<fromModels.ITeacherApplication>(
      `${environment.apiURL}/organizations/${organizationId}/teachers/me/draft`,
      updateDto
    );
  }
}
