import { TokenInterceptor } from "./token-interceptor.service";
import { RedirectService } from "./redirect.service";
import { AuthService } from "./auth.service";
import { MessagingService } from "./messaging.service";
import { TeacherService } from "./teacher.service";
// import { TimeZoneInterceptor } from './time-zone.interceptor.service';
import { ScriptService } from "./script.service";

export const SERVICES = [
  AuthService,
  RedirectService,
  TokenInterceptor,
  MessagingService,
  TeacherService,
  // TimeZoneInterceptor,
  ScriptService,
];

export { TokenInterceptor } from "./token-interceptor.service";
export { RedirectService } from "./redirect.service";
export { AuthService } from "./auth.service";
export { MessagingService } from "./messaging.service";
export { TeacherService } from "./teacher.service";
// export { TimeZoneInterceptor } from './time-zone.interceptor.service';
export { ScriptService } from "./script.service";
