import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Store, select } from "@ngrx/store";

import * as fromStore from "../store";

@Injectable({
  providedIn: "root",
})
export class MemberGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<fromStore.IRootState>
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(fromStore.getQueryParamsByRouter),
      filter((queryParams) => !!queryParams),
      map((queryParams) => {
        if (queryParams.action) {
          return true;
        } else {
          this.router.navigate(["/"]);
          return false;
        }
      })
    );

    // return this.store.pipe(
    //   select(fromStore.getCurrentUser),
    //   // filter((user: fromModels.IUser) => !!user),
    //   map((user: fromModels.IUser) => {
    //
    //     if (user == undefined) {
    //       this.store.dispatch(fromActions.loadUser());
    //       zip(
    //         this.store.pipe(
    //           select(fromStore.getCurrentUser),
    //           filter((user) => !!user)
    //         )
    //       ).subscribe((user) => {
    //

    //         if (user[0]?.organizations[0]?.role?.type == 'MEMBER') {
    //           // this.router.navigate(['/']);
    //           return true;
    //         } else {
    //           this.router.navigate(['/']);
    //           return false;
    //         }
    //       });
    //     } else {
    //       if (user?.organizations[0]?.role?.type === 'MEMBER') {
    //         return true;
    //       } else {
    //         this.router.navigate(['/']);
    //         return false;
    //       }
    //     }
    //   })
    // );
  }
}
