import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { MessagingService } from "../../services";
import * as fromActions from "../actions";
import { getCurrentUser } from "../selectors";
import { IRootState } from "./../reducers";

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private messaging: MessagingService,
    private snackBar: MatSnackBar,
    private store: Store<IRootState>
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => fromActions.loadFirebaseToken())
    )
  );

  loadFirebaseToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFirebaseToken),
      switchMap(() =>
        this.messaging.requestToken().pipe(
          map((token) =>
            fromActions.loadFirebaseTokenSuccess({ payload: token })
          ),
          catchError((error) =>
            of(
              fromActions.loadFirebaseTokenFailure({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  loadFirebaseTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadFirebaseTokenSuccess),
      withLatestFrom(this.store.pipe(select(getCurrentUser))),
      filter(([{ payload }, user]) => !!payload && !!user),
      map(([{ payload }, user]) =>
        fromActions.updateUser({
          payload: { firebaseToken: payload, deviceId: "web" },
        })
      )
    );
  });

  loadFirebaseTokenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loadFirebaseTokenFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to request firebase token, ${payload.message}`,
            "",
            {
              duration: 3000,
              panelClass: ["failure-snackbar"],
            }
          )
        )
      );
    },
    { dispatch: false }
  );
}
