import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import * as fromDto from "../dto";
import * as fromModels from "../models";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  getLogoAndImages(organizationId: string): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiURL}/organizations/${organizationId}/logo-images`
      )
      .pipe(
        tap((response) => {
          this.setLogoAndImages(response);
        })
      );
  }

  getLanguages(organizationId: string): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiURL}/languages/organizations/${organizationId}/words`
      )
      .pipe(
        tap((response) => {
          for (const language in response.result)
            this.setLanguage(language, response.result[language]);
        })
      );
  }

  login(credentials: fromDto.LoginUserDto): Observable<fromModels.IUser> {
    return this.httpClient.post<fromModels.IUser>(
      `${environment.apiURL}/users/login`,
      credentials
    );
  }

  /*
    refresh token request send old refresh token and set new refresh token and set new access token
   
   */
  refreshToken(): Observable<fromModels.IUser> {
    return this.httpClient
      .put<fromModels.IUser>(`${environment.apiURL}/users/refresh-token`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap(({ refreshToken, accessToken }: fromModels.IUser) => {
          this.setRefreshToken(refreshToken);
          this.setAccessToken(accessToken);
        })
      );
  }

  signup(credentials: fromDto.CreateUserDto): Observable<fromModels.IUser> {
    // Form data so the user can send a file in the request
    const formData = new FormData();

    // Fill the form with the request DTO
    Object.entries(credentials).forEach(([key, value]) => {
      // Append to FormData only if the value is not null
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    });
    return this.httpClient.post<fromModels.IUser>(
      `${environment.apiURL}/users/signup/teacher`,
      formData
    );
  }

  signupWithInvitation(
    credentials: fromDto.CreateUserDto
  ): Observable<fromModels.IUser> {
    return this.httpClient.post<fromModels.IUser>(
      `${environment.apiURL}/organizations/signup-with-invitation`,
      credentials
    );
  }

  setRefreshToken(token: string): void {
    return localStorage.setItem("refreshToken", token);
  }

  setLogoAndImages(response: any): void {
    localStorage.setItem("logo", response.logo);
    localStorage.setItem("sideBarImage", response.sideBarImage);
    localStorage.setItem("authImage", response.authImage);
    localStorage.setItem(
      "isCustomerRatingActive",
      response.isCustomerRatingActive
    );
  }

  setLanguage(language: string, response: any): void {
    localStorage.setItem(language, JSON.stringify(response));
  }

  setAccessToken(token: string): void {
    return localStorage.setItem("accessToken", token);
  }

  setOrganizationId(id: string): void {
    return localStorage.setItem("organizationId", id);
  }

  getRefreshToken(): string {
    return localStorage.getItem("refreshToken");
  }

  getAccessToken(): string {
    return localStorage.getItem("accessToken");
  }

  getOrganizationId(): string {
    return localStorage.getItem("organizationId");
  }

  removeRefreshToken(): void {
    return localStorage.removeItem("refreshToken");
  }

  removeAccessToken(): void {
    return localStorage.removeItem("accessToken");
  }

  removeOrganizationId(): void {
    return localStorage.removeItem("organizationId");
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  forgetPassword(email: fromDto.ForgetPasswordDto) {
    return this.httpClient.put(
      `${environment.apiURL}/users/forget-password`,
      email
    );
  }

  resetPassword({ email, resetToken, newPassword }: fromDto.ResetPasswordDto) {
    return this.httpClient.put(`${environment.apiURL}/users/reset-password`, {
      resetToken,
      newPassword,
      email,
    });
  }

  verify({
    email,
    verificationCode,
  }: fromDto.VerifyUserDto): Observable<fromModels.IUser> {
    return this.httpClient.post<fromModels.IUser>(
      `${environment.apiURL}/users/verify`,
      { verificationCode, email }
    );
  }

  getCurrentUser() {
    return this.httpClient.get<fromModels.IUser>(
      `${environment.apiURL}/users/me`
    );
  }

  updateUser(dto: fromDto.UpdateUserDto): Observable<fromModels.IUser> {
    const formData = new FormData();
    Object.keys(dto).forEach((key: string) => {
      formData.append(key, dto[key]);
    });

    return this.httpClient.patch<fromModels.IUser>(
      `${environment.apiURL}/users`,
      formData
    );
  }

  resendVerificationCode(email: string) {
    return this.httpClient.post<void>(
      `${environment.apiURL}/users/resend-verification-code-by-email`,
      { email }
    );
  }

  logoutOrganization(organizationId: string) {
    return this.httpClient.post(
      `${environment.apiURL}/organizations/${organizationId}/logout`,
      { deviceId: "web" }
    );
  }

  verifyUpdateEmail({
    email,
    verificationCode,
  }: fromDto.VerifyUserDto): Observable<fromModels.IUser> {
    return this.httpClient.post<fromModels.IUser>(
      `${environment.apiURL}/users/verify-update-email`,
      { verificationCode, email }
    );
  }

  resendUpdateEmailVerificationCode(email: string) {
    return this.httpClient.post<void>(
      `${environment.apiURL}/users/resend-update-email-verification-code-by-email`,
      { email }
    );
  }
}
