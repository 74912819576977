import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";

import { Store, select } from "@ngrx/store";

import * as fromStore from "../store";
import * as fromActions from "../store/actions";

import * as fromModels from "../models";
import { ORGANIZATION_ID } from "../constants";

@Injectable({
  providedIn: "root",
})
export class TeacherGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<fromStore.IRootState>
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store
      .pipe(
        select(fromStore.getTeacherTeacher)
        // filter((teacher) => !!teacher)
      )
      .pipe(
        switchMap((teacher) =>
          this.store.pipe(
            select(fromStore.getCurrentUser),
            filter((user: fromModels.IUser) => !!user),
            map((user: fromModels.IUser) => {
              if (user?.organizations[0]?.role?.type === "TEACHER") {
                if (teacher === undefined) {
                  this.store.dispatch(
                    fromActions.loadTeacher({
                      payload: { organizationId: ORGANIZATION_ID },
                    })
                  );
                }

                return true;
              } else {
                this.router.navigate(["/"]);
                return false;
              }
            })
          )
        )
      );
  }
}
