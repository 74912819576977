import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  map,
  catchError,
  tap,
  switchMap,
  mergeMap,
  debounceTime,
} from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { of } from "rxjs";
import * as fromServices from "../../services";
import * as fromActions from "../actions";
import { Router } from "@angular/router";

@Injectable()
export class TeacherEffects {
  constructor(
    private actions$: Actions,
    private teacherService: fromServices.TeacherService,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: fromServices.AuthService
  ) {}

  // load teacher effects

  loadTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTeacher),
      switchMap(({ payload }) =>
        this.teacherService.loadTeacher(payload.organizationId).pipe(
          map((data) =>
            fromActions.loadTeacherSuccess({
              payload: data,
            })
          ),
          catchError((error) =>
            of(
              fromActions.loadTeacherFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  loadTeacherFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loadTeacherFailure),
        tap(({ payload }) => {
          this.authService.removeAccessToken();
          this.authService.removeRefreshToken();
          this.router.navigate(["/auth"]),
            this.snackBar.open(`${payload.message} `, "", {
              duration: 5000,
              panelClass: ["failure-snackbar"],
            });
        })
      );
    },
    { dispatch: false }
  );

  // update teacher effects

  updateTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTeacher),
      switchMap(({ payload }) =>
        this.teacherService.updateTeacher(payload).pipe(
          map((data) =>
            fromActions.updateTeacherSuccess({
              payload: data,
            })
          ),
          catchError((error) =>
            of(
              fromActions.updateTeacherFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  updateTeacherSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.updateTeacherSuccess),
        tap(({ payload }) => {
          this.snackBar.open(
            ` Your Information has been updated successfully `,
            "",
            {
              duration: 2000,
              panelClass: ["success-snackbar"],
            }
          );
          // if (payload.cvReviewStatus === "FINISHED_ONBOARDING") {
          //   this.router.navigate(["/teacher/admin-review"]);
          // }
        })
      );
    },
    { dispatch: false }
  );

  updateTeacherFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.updateTeacherFailure),
        tap(({ payload }) => {
          this.snackBar.open(`${payload.message} `, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          });
        })
      );
    },
    { dispatch: false }
  );
}
